$brand-primary: #FF0000;
$brand-primary-soft: #666666;
$brand-muted: #ECECEC;
$header-bar-logo-height: 4.5rem;

@import '../style';

app-main-menu {
    background: $brand-primary-soft;
}

.layout {
    &--inverse {
        background: $brand-primary-soft;
    }
}

.landing-layout {
    background: $brand-primary-soft;
}

.wms-dialog .mat-mdc-dialog-container .wms-dialog-inner {
    background: $brand-primary-soft;
}
